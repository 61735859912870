.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
}

.Header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  border-radius: 10px;
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.headerText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.headerText h5 {
  font-weight: 500;
  margin: 0;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.headerButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
}
.headerButton button {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 99;
  top: 0;
}
.alertSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.alertSection p {
  font-size: 1.25rem;
  color: green;
  text-transform: capitalize;
}
.alertSection p:nth-child(2) {
  font-size: 1.15rem;
  color: #99334d;
}
.confirmationSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0;
}
.asessmentCreationSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}
.selectionSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem;
}

.generationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
}
.generationValue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}
.generationValue p {
  font-size: 1.25rem;
  font-weight: 500;
}
.asessmentTypes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
}
.questionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 75vh; */
  justify-content: space-between;
  padding: 1rem;
  border: 2px solid #efefef;
  margin: 1rem;
  box-shadow: 0 3px 8px #0000001f;
  /* overflow: scroll; */
}
.noSelection {
  width: 100%;
  min-height: 75vh;
  align-items: center;
  display: flex;
  justify-content: center;
}
.fillQuestionSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
}
.questionCreation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  height: 50%;
}
.creationTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
  height: 20%;
}
.questionCreationTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.questionCreationTop svg {
  font-size: 1.5rem;
  color: #2e2e89;
}
.questionCreationTop p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0.75rem;
  text-transform: capitalize;
}
.questionCreationTop span {
  font-size: 1.5rem;
  color: red;
  font-weight: 700;
}
.creationTopLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}
.creationTopLeft svg {
  font-size: 1.5rem;
  color: #2e2e89;
}
.creationTopLeft p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0.75rem;
  /* text-transform: capitalize; */
}
.creationTopLeft span {
  font-size: 1.5rem;
  color: red;
  font-weight: 700;
}
.creationTopRight {
  display: flex;
  align-items: center;
  justify-content: center;
}
.creationBottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.creationBottomBlank {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
  height: 75%;
}
.textArea {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.formatButtons {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}
.optionSet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  padding: 0.75rem 0;
  position: sticky;
  top: 1;
  /* z-index: 1000; */
  
}
.optionTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  margin-top: 1.5rem;
}
.optionTop svg {
  font-size: 1.5rem;
  color: #39c95b;
}
.optionTop p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0.75rem;
  /* text-transform: capitalize; */
}
.optionTop p:nth-child(3) {
  color: #094778;
  font-size: 1.15rem;
  font-weight: 400;
}
.optionTop span {
  font-size: 1.5rem;
  color: red;
  font-weight: 700;
}
.optionBottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
}
.optionBottomLeft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 1rem 0;
}
.blankValues {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 0.5rem 0;
}
.blankValues p {
  font-size: 1.25rem;
  color: #740202;
  font-weight: 700;
  margin-right: 0.5rem;
}
.optionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
}
.optionBottomRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
}
.mainOptionSet {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 0;
  margin-top: 1.5rem;
}
.firstOptionSet {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-start;
  justify-content: flex-start;
}
.firstOptionTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.firstOptionTop svg {
  font-size: 1.5rem;
  color: #c93966;
}
.firstOptionTop p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0.75rem;
  text-transform: capitalize;
}
.firstOptionSetValues {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.firstOptionAdd {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  gap: 1rem;
}
.secondOptionSet {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-start;
  justify-content: flex-start;
}
.secondOptionTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.secondOptionTop svg {
  font-size: 1.5rem;
  color: #c9c639;
}
.secondOptionTop p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0.75rem;
  text-transform: capitalize;
}
.secondOptionSetValues {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.secondOptionAdd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.matchAnswerKey {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-start;
  justify-content: flex-start;
}
.matchAnswerTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.matchAnswerTop p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0.75rem;
  text-transform: capitalize;
}
.matchAnswerValues {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.matchAnswerTop svg {
  font-size: 1.5rem;
  color: #55c939;
}
.reArrangeOptionSet {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: flex-start;
  justify-content: flex-start;
}
.reArrangeAnswerKey {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: flex-start;
  justify-content: flex-start;
}
.answerKeyAdd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.actionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
.questionCreateButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
.questionCreateButton button {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  /* width: 115px; */
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
}
.settingsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.settingsIcon svg {
  font-size: 1.5rem;
}
.questionClearButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.75rem;
}
.questionClearButton button {
  width: 8rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.aiGenerationSection {
  width: 100%;
  height: 75vh;
  align-items: center;
  display: flex;
  justify-content: center;
}
.aiButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.aiButton button {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
}

.settingsListing {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  width: 25%;
  align-items: center;
}
.settingHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* box-shadow: 0 3px 8px #0000001f; */
  padding: 0.5rem 0;
}
.headerSettingText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerSettingText h5 {
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #727272;
}
.settingClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.settingClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.settingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
}
.mainSection {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
  gap: 4rem;
  padding: 0.25rem 0;
}
.sectionValues {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
.sectionValues label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #393979;
}
.sectionValues input {
  font-family: "Poppins";
  width: 100%;
  font-size: 26px;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
  text-align: start;
}
.settingButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.add {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.MicSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.MicSection button {
  margin: 1.5rem 0;
}
.MicSection input {
  /* padding:10px 4px */
}
.optionBottomDictation {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
}
.ImageInput {
  width: 100%;
}
.WhiteBoradContainer {
  width: 60rem;
  height: 36rem;
  border-radius: 16px;
  background-color: #fff;
  position: fixed;
  top: 19%;
  right: 1%;
  border: 1px solid #dbdbdb;
  /* transform: translate(-50%, -50%); */
  animation: slideIn 0.5s forwards;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 99;
}
.closing {
  animation: slideOut 0.5s; /* Adjust the duration as needed */
}
@keyframes slideIn {
  from {
    right: -60rem; /* Off-screen initially */
  }
  to {
    right: 1rem; /* Final position */
  }
}
@keyframes slideOut {
  from {
    right: 1rem; /* Initial position when closing */
  }
  to {
    right: -60rem; /* Move off-screen to the right */
  }
}
.WhiteBoradHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 0rem 1rem;
  margin: 0;
  border: none;
  border-radius: 15px 15px 0 0;
}
.WhiteBoradIcon {
  cursor: pointer;
  font-size: 1.25rem;
  padding: 8px;
}
.WhiteBoradContent {
  height: 32rem;
  padding: 0.5rem 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chatboticon {
  position: fixed;
  top: 50%;
  right: 0%;
  /* color: #fff;  */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* animation: slideIn 0.5s forwards; */
  z-index: 99;
}

.Iconhead {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.icontext {
  padding: 0.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  /* background-color: #e4c395; */
  border-radius: 6px;
  /* border: 1px solid; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1a1e5d;
  background-color: #fff;
}
.icontext p {
  margin: 0;
  padding: 0;
}
.WhiteboardFloatingIcon {
  font-size: 1.5rem;
  margin: 2px 0;
  color: #1a1e5d;
}
.ChatImage {
  width: 11rem;
  /* border-radius: 50%; */
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  /* background-color: #0f8bea; */
}
.ChatImage img {
  width: 100%;
}
.crosswordcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  justify-content: space-between;
}
.crosswordpuzzle {
  display: grid;
  grid-template-columns: repeat(10, 50px);
  grid-template-rows: repeat(10, 50px);
  gap: 0;
  padding: 4px;
  /* position: -webkit-sticky;  */
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 1000; */
  
}

.PuzzleQuestion {
  padding: 0 .5rem;
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  overflow: scroll;
  overflow-x: hidden;
}

.cell {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  border: 1px solid #000;
  padding: 0.75rem; /* Remove padding */
  margin: 0; /* Remove margin */
  outline: none; /* Remove outline */
}
.clueNumber {
  position: absolute;
  top: 0;
  right: 0;
  font-size: smaller;
}

.QuestionContainer {
  resize: none; 
  overflow-x: auto; 
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #bfaaaac1;
  font-size: 1.15rem;
  box-sizing: border-box; 
  white-space: nowrap; 
  width: 100%; 
}

.QuestionContainer:focus {
  border-color: #bfaaaac1; 
  outline: none; 
}

.QuestionAcrossBox{
  width: 100%;

}
.QuestionAcross {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.3rem;
  border-radius: 10px;
  background-color: #1795f562;

}
.QuestionAcrossInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.3rem;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 1rem 0 1rem 0;
}
.QuestionAcrossInputContainer input {
  /* font-family: "Poppins"; */
  width: 100%;
  font-size: 1.15rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 1px solid #bfaaaac1;
  outline: none;
  text-align: start;
}

.QuestionDownBox{
  width: 100%;
}

.QuestionDown {
  display: flex;
  flex-direction: column;
  padding: 1.3rem;
  /* margin-top: 2rem; */
  border-radius: 10px;
  background-color: #1795f562;
  width: 100%;
}
.QuestionDownInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.3rem;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 1rem 0 1rem 0;
}
.QuestionDownInputContainer input {
  /* font-family: "Poppins"; */
  width: 100%;
  font-size: 1.15rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 1px solid #bfaaaac1;
  outline: none;
  text-align: start;
}
.cellContainer {
  position: relative;
}

.clueNumber {
  position: absolute;
  top: 2px; /* Adjust this value to fine-tune the vertical position */
  right: 2px; /* Adjust this value to fine-tune the horizontal position */
  font-size: 10px; /* Adjust this value for the desired font size */
  color: red; /* Adjust this value for the desired color */
}
.MediaPopup {
  position: absolute;
  top: 50%;
  left: 57%;
  z-index: 999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 80%;
}
.MediaPopupHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0 3px 8px #0000001f;
  padding: 0.5rem 0;
}
.MediaPopupHeaderText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.MediaPopupHeaderText h5 {
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #727272;
  padding: 0 6px;
}
.MediaPopupHeaderClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.MediaPopupHeaderClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.MediaPopupSection {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
}
.ImageGRid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  height: 60vh;
  padding: 8px;
  overflow-y: scroll;
}
.ImageGRid::-webkit-scrollbar {
  display: none;
}
.Card {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  height: 12rem;
}
.Card img {
  width: 100%;
}
.tabSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.MediaPopupInputTab {
  height: 60vh;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.MediaPopupInputTab button {
  border: none;
  background: #9f2f2f;
  font-size: 1.25rem;
  color: #ffff;
  padding: 0.75rem;
  width: 150px;
  border-radius: 5px;
  margin: 0 0.75rem;
}

.explanation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.explanation h4 {
  padding: 4px 0;
}
.explanationTextArea {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}

.crossAction{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delBtnIco{
  font-size: 2rem;
  color: #0081e4;
  cursor: pointer;
}

.delBtnIco:hover{
  color: #cc0000;
}

.PuzzleQuestion::-webkit-scrollbar {
  width: 10px;
}

.PuzzleQuestion::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

.PuzzleQuestion::-webkit-scrollbar-thumb {
  background: #005096;
  border-radius: 10px;
}

.PuzzleQuestion::-webkit-scrollbar-thumb:hover {
  background: #012c52;
}

.delBox{
  font-size: 1.5rem;
  color: #005096;
  cursor: pointer;
}
.delBox:hover{
  color: red;
}

.dragAndDropContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.optionsSection {
  margin-top: 10px;
}
.optionsSection h4{
  padding-bottom: 6px;
}

.optionsInput {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.inputBox {
  flex: 1;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
}

.addButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.addButton:hover {
  background-color: #0056b3;
}

.optionsBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  min-height: 20vh;
  margin: 6px 0;
}

.optionCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.optionText {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}

.removeButton {
  background: none;
  border: none;
  color: #ff4d4d;
  font-size: 16px;
  cursor: pointer;
}

.removeButton:hover {
  color: #d11a1a;
}



.columnsSection {
  margin-top: 20px;
  overflow-x: auto; /* Allow scrolling for smaller screens */
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.table thead th {
  border-bottom: 2px solid #ccc;
  background-color: #e9ecef;
  padding: 8px;
  text-align: center;
}

.tableHeader input {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.tableHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tableCell {
  padding: 8px;
  border: 1px solid #ddd;
}

.addColumnButton {
  padding: 8px 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  height: fit-content;
}

.addColumnButton:hover {
  background-color: #218838;
}

.addRowButton {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.addRowButton:hover {
  background-color: #0056b3;
}
.cardInCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: fit-content;
  cursor: default;
  width: fit-content;
}

.cardText {
  flex-grow: 1;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.removeCardButton {
  background: none;
  border: none;
  color: red;
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
}

.tableCell {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  min-height: 40px;
  position: relative;
}

.tableCell:hover {
  background-color: #f9f9f9;
}
