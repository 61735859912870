.container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #0b0f7705;
}
.container::-webkit-scrollbar {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 6rem;
  padding-left: 4rem;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.headerText h3 {
  font-weight: 800;
  color:rgb(0, 0, 139) ;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.form {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 4rem; 
}

.createBox {
  width: 100vh;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.createBox form{
  padding-bottom: 10px;
}


.eventDetialsBox  {
  padding-bottom: 10px;
}


.eventDetialsBox p {
  padding-bottom: 0.5rem;
}

.modeContainer {
  width: 100%;
}

.modePosterTime {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.eventPoster {
  border: solid 1px rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  padding: 10px;
  height: 35vh;
  width: 50%;
}

.quill {
  width: 100%;
  height: 30vh;
  overflow: hidden;
  padding-bottom: 5rem;
}

.uploadBtn button {
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  font-weight: 600;
  background-color: #2977ff;
  color: white;
  display: flex;
  justify-content: center;
  width: 7rem;
  height: 2rem;
  margin: 1rem;
}

.uploadBtn input {
  display: none;
}

.detailVenue{
  display: flex;
}

.imgIcon {
  font-size: 1.3rem;
}


.posterImage {
  height: 40vh;
  width: 100%;
  padding: 1rem;
}

.posterImage img {
  height: 50%;
  width: 100%;
}

.eventPoster .remove {
  color: rgb(255, 0, 0);
  cursor: pointer;
  font-size: 1.5rem;
}

.eventDetails {
  border: solid 1px rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  padding-top: 0.5rem;
  height: 30vh;
}

.focused {
  border: solid 2px #2977ff;
}

.venueBox {
  display: flex;
  gap: 2rem;
  padding-top: 20px;
}

.venueContianer {
  width: 70vh;
}

.venue {
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.26);
}

.venue p {
  padding: 1rem;
  font-weight: 600;
}

.modeMenu {
  width: 100%;
  padding-bottom: 10px;
}

.dateTime {
  width: 50%;
}

.dateTime input {
  font-size: 1rem;
  font-weight: 600;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin: 10px 0;
  border: 1px solid #696969c1;
  outline: none;
  height: 3rem;
  width: 100%;
}

.dateTime input:focus {
  border: solid 2px #2977ff;
}

.submitButton button {
  padding: 1rem;
  font-weight: 700;
  border: none;
  cursor: pointer;
  background-color: rgb(47, 128, 0);
  border-radius: 5px;
  color: white;
}

.submitButton {
  display: flex;
  justify-content: flex-end;
}
