.container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
}
  .container::-webkit-scrollbar {
    display: none;
  }
.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.27);
}
.headerText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.headerText h5 {
  font-weight: 500;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.quizSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.quizEditSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 80vh;
  justify-content: space-between;
  padding: 1rem;
  border: 2px solid #efefef;
  margin: 1rem;
  box-shadow: 0 3px 8px #0000001f;
}
.quizNumberList {
  display: flex;
  align-items: flex-start;
  width: 30%;
  justify-content: center;
  height: 80vh;
}
.fillQuestionSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.questionCreation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  height: 50%;
}
.creationTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
  height: 20%;
}
.creationTopLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}
.creationTopLeft svg {
  font-size: 1.5rem;
  color: #2e2e89;
}
.creationTopLeft p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0.75rem;
  text-transform: capitalize;
}
.creationTopLeft span {
  font-size: 1.5rem;
  color: red;
  font-weight: 700;
}
.creationTopRight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.25rem;
}
.actionButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
.creationTopRight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.25rem;
}
.settingsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.settingsIcon svg {
  font-size: 1.5rem;
}
.questionCreateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  /* height: 10%; */
  /* margin: 0 0.5rem; */
}
.questionCreateButton svg {
  font-size: 2rem;
  color: #3adc28;
  cursor: pointer;
}
.creationBottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  height: 18vh;
}
.creationBottomBlank {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
  height: 70%;
}
.formatButtons {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}
.textArea {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.optionSet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  padding: 0.75rem 0;
}
.optionTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  margin-top: 1.5rem ;
}
.optionTop svg {
  font-size: 1.5rem;
  color: #39c95b;
}
.optionTop p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0.75rem;
  /* text-transform: capitalize; */
}
.optionTop p:nth-child(3) {
  color: #094778;
  font-size: 1.15rem;
  font-weight: 400;
}
.optionTop span {
  font-size: 1.5rem;
  color: red;
  font-weight: 700;
}
.optionBottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
}
.optionBottomLeft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
}
.blankValues {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 0.5rem 0;
}
.blankValues p {
  font-size: 1.25rem;
  color: #740202;
  font-weight: 700;
  margin-right: 0.5rem;
}
.dictationValues {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
}
.optionBottomDictation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.MicSection {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin: 0.75rem 0;
  padding: 0.75rem 0;
}

.firstOptionAdd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.optionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
}
.firstOptionAdd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.mainOptionSet {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 0;
  margin-top: 1.5rem;
}
.firstOptionSet {
  display: flex;
  flex-direction: column;
  width: 33%;
  align-items: flex-start;
  justify-content: flex-start;
}
.firstOptionTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.firstOptionTop svg {
  font-size: 1.5rem;
  color: #c93966;
}
.firstOptionTop p {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0.5rem;
  text-transform: capitalize;
}
.firstOptionSetValues {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.secondOptionSet {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-start;
  justify-content: flex-start;
}
.secondOptionTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.secondOptionTop svg {
  font-size: 1.5rem;
  color: #c9c639;
}
.secondOptionTop p {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0.75rem;
  text-transform: capitalize;
}
.secondOptionSetValues {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.secondOptionAdd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.matchAnswerKey {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-start;
  justify-content: flex-start;
}
.matchAnswerTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.matchAnswerTop p {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0.75rem;
  text-transform: capitalize;
}
.matchAnswerValues {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.matchAnswerTop svg {
  font-size: 1.5rem;
  color: #55c939;
}

.answerKeyAdd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.ImageInput {
}
.settingsListing {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
}
.settingHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0 3px 8px #0000001f;
  padding: 0.5rem 0;
}
.headerSettingText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerSettingText h5 {
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #727272;
}
.settingClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.settingClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.settingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
}
.mainSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  padding: 0.25rem 0;
  flex-direction: column;
}
.sectionValues {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: flex-start;
  justify-content: flex-start;
}
.mainSection label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #393979;
}
.mainSection input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
  text-align: start;
}
.settingButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.add {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
  margin: 0 8px;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
  margin: 0 8px;
}
.crosswordcontainer{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.crosswordpuzzle {
  display: grid;
  grid-template-columns: repeat(10, 50px);
  grid-template-rows: repeat(10, 50px);
  gap: 0;
  padding: 4px;
  
}

.PuzzleQuestion{
  padding: 0 1rem;
  width: 100%;
}

.cell {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  border: 1px solid #000;
  padding: .75rem; /* Remove padding */
  margin: 0; /* Remove margin */
  outline: none; /* Remove outline */
}
.clueNumber {
  position: absolute;
  top: 0;
  right: 0;
  font-size: smaller;
}
.QuestionAcross{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.QuestionAcrossInputContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.QuestionAcrossInputContainer input{
  font-family: "Poppins";
  width: 100%;
  font-size: 1.15rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 1px solid #bfaaaac1;
  outline: none;
  text-align: start;
}

.QuestionDown{
  display: flex;
  flex-direction: column;
}
.QuestionDownInputContainer{
  display: flex;
  flex-direction: column;
}
.QuestionDownInputContainer input{
  font-family: "Poppins";
  width: 100%;
  font-size: 1.15rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 1px solid #bfaaaac1;
  outline: none;
  text-align: start;
}
.cellContainer {
  position: relative;
}

.clueNumber {
  position: absolute;
  top: 2px;  /* Adjust this value to fine-tune the vertical position */
  right: 2px;  /* Adjust this value to fine-tune the horizontal position */
  font-size: 10px;  /* Adjust this value for the desired font size */
  color: red;  /* Adjust this value for the desired color */
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
}
.MediaPopup {
  position: absolute;
  top: 50%;
  left: 57%;
  z-index: 999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 80%;
}
.MediaPopupHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0 3px 8px #0000001f;
  padding: 0.5rem 0;
}
.MediaPopupHeaderText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.MediaPopupHeaderText h5 {
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #727272;
  padding: 0 6px;
}
.MediaPopupHeaderClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.MediaPopupHeaderClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.MediaPopupSection {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
}
.ImageGRid{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  height: 60vh;
  padding: 8px;
  overflow-y: scroll;
}
.ImageGRid::-webkit-scrollbar{
  display: none;
}
.Card{
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  height: 12rem;
}
.Card img{
width: 100%;
}
.tabSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.MediaPopupInputTab{
  height: 60vh;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
}
.MediaPopupInputTab button {
  border: none;
  background: #9f2f2f;
  font-size: 1.25rem;
  color: #ffff;
  padding: 0.75rem;
  width: 150px;
  border-radius: 5px;
  margin: 0 0.75rem;
}