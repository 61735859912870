.Container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 0.5rem;
  }
  .Container::-webkit-scrollbar {
    display: none;
  }
  .Header {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    color: #131010;
    height: 4rem;
    /* border-radius: 10px; */
    margin: 1rem;
    box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
      0.3em 0.3em 1em #6d6d6d4d;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .HeaderText {
    width: 80%;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: flex-start;
  }
  .HeaderText p {
    font-weight: 500;
    margin: 0;
    font-size: 1.35rem;
    text-transform: uppercase;
  }
  .HeadButton {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
  }
  .HeadButton button {
    background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  width: 115px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
  }
  .table {
    width: 100%;
    border-spacing: 0;
  }
  .table tr {
    text-align: center;
  }
  .table th {
    background-color: #542b47;
    width: 10rem;
    height: 3rem;
    color: #fff;
    border: 1px solid #4a4450;
    font-size: 1.25rem;
  }
  .table td {
    height: 3rem;
    border: 1px solid #a08ea4;
    color: #2f6f72;
  }
  .Buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    font-size: 1.5rem;
  }
  .iconContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltipText {
    visibility: hidden;
    width: 80px;
    background-color: #857c7c;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* padding: 5px; */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 1rem;
  }
  
  .iconContainer:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
  
  .editButton {
    color: #0076de;
  }
  .deleteButton {
    color: red;
  }
  .deletePopup {
    width: 25rem;
    position: absolute;
    top: 50%;
    left: 55%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    align-items: center;
  }
  .deleteClose {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: 1.5rem;
    color: #3e4486;
  }
  .deleteSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .popupText {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
  }
  .popupText p {
    font-size: 1.25rem;
    color: #3e4486;
  }
  .popupDelete {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 50%;
    margin: 1rem 0;
  }
  .orgDelete {
    border-radius: 6px;
    background-color: #e1e4ee;
    outline: none;
    font-size: 1.15rem;
    color: #20126d;
    padding: 0.5rem 1rem;
    border: 2px solid #b2a5d3;
    cursor: pointer;
  }
  .orgDeleteCancel {
    border-radius: 6px;
    background-color: #eee6e1;
    border: none;
    outline: none;
    font-size: 1.15rem;
    color: #b01616;
    padding: 0.5rem 1rem;
    border: 2px solid #f9a1a1;
    cursor: pointer;
  }
  .memberCreate {
    width: 30rem;
    position: absolute;
    top: 50%;
    left: 55%;
    z-index: 999999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    border-radius: 1rem;
    padding:.5rem 1rem;
    align-items: center;
  }
  .memberClose {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: 1.5rem;
  }
  .membersection {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0;
    /* align-items: center; */
    justify-content: center;
    /* height: 70%; */
  }
  .membersection input {
    font-family: "Poppins";
    width: 100%;
    font-size: 1.25rem;
    padding: 0.5rem 0;
    text-align: center;
    border-radius: 5px;
    margin: 0.3rem 0;
    border: 2px solid #696969c1;
    outline: none;
    color: #3e4486;
  }
  .membersection input::-webkit-input-placeholder {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.25rem;
    color: #a7a6a6;
  }
  .roleSelection {
    font-family: "Poppins";
    width: 100%;
    font-size: 1.25rem;
    padding: 0.5rem 0;
    text-align: center;
    border-radius: 5px;
    margin: 0.3rem 0;
    border: 2px solid #696969c1;
    outline: none;
    color: #3e4486;
    text-align: center;
    text-transform: uppercase;
  }
  .createButton {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    /* height: 30%; */
    padding: .5rem;
  }
  .popupButton {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 50%;
  }
  .create {
    width: 10rem;
    border-radius: 6px;
    background-color: #e1e4ee;
    outline: none;
    font-size: 1.15rem;
    color: #20126d;
    padding: 0.5rem;
    border: 2px solid #b2a5d3;
    cursor: pointer;
  }
  .cancel {
    width: 10rem;
    border-radius: 6px;
    background-color: #eee6e1;
    border: none;
    outline: none;
    font-size: 1.15rem;
    color: #b01616;
    padding: 0.5rem;
    border: 2px solid #f9a1a1;
    cursor: pointer;
  }
  
  .Overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color:rgba(4, 4, 4, 0.793);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
  .select_section {
    display: flex;
  align-items: center;

  justify-content: center;
  padding: 1rem;
  border: 2px solid #efefef;
  border-radius: 16px;
  margin: 1rem;
  box-shadow: 0 3px 8px #0000001f;
  }
  .org_select {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  .org_select select {
    border: none;
    background: none;
    border-bottom: 2px solid #939393;
    padding: .75rem 0;
    width: 300px;
    outline: none;
    font-size: 1.25rem;
    color: #8f2f2f;
  }
  .club_select {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  .club_select select {
    border: none;
    background: none;
    border-bottom: 2px solid #939393;
    padding: 1rem 0;
    width: 300px;
    outline: none;
    font-size: 1.25rem;
    color: #8f2f2f;
  }
  .errorMessages {
    padding: 0rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: red;
    font-size: 1.2rem;
  }
  .errorMessages p{
    margin: 4px 0;
  }
.checkboxContainer .checkboxInput{
  margin-right: 5px;
  width: 5%;
  transform: scale(1.1);
}
.checkboxContainer .checkboxInputLabel{
  font-size: 1.1rem;
}
.checkboxGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust column width as needed */
  gap: 10px; /* Adjust the gap between items */
}

.checkboxItem {
  display: flex;
  align-items: center;
}
.FeatureList{
  margin: 4px 0;
  width: 40rem;
  padding: 0 1rem;
}
.FeatureHead{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}
.FeatureHead p{
  margin: 0;
  text-decoration: underline;
  font-size: 1.25rem;
  color: #181818;
  font-weight: 600;
}
.Featurebutton{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 4px 0;
}
.Featurebutton button{
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
}
.categoryName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.25rem;
  justify-content: flex-start;
  width: 100%;
}

.categoryName label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #393979;
}
.categoryDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.25rem;
  justify-content: flex-start;
  width: 100%;
}
.categoryDescription label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #74174e;
}