.Container {
  width: 100%;
  height: 100vh;
}
.Header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  border-radius: 10px;
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.Title {
  width: 80%;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: flex-start;
}
.Title p {
  font-weight: 500;
  margin: 0;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.Search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.searchSection {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
  padding: 1.25rem;
  border-radius: 15px;
  margin: 1rem;
  height: 5rem;
}
.searchHeader {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #939393;
  line-height: 0;
  padding: 0 1rem;
  justify-content: space-between;
}
.searchHeader svg {
  font-size: 1.5rem;
  color: #0f0f21;
}
.searchHeader input {
  border: none;
  background: no-repeat;
  outline: none;
  width: 100%;
  font-size: 1.25rem;
}
.searchHeader input::-webkit-input-placeholder {
  color: #332a2a;
  font-size: 1.25rem;
  text-transform: capitalize;
}
.SearchResult {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  margin: 1rem;
  flex-direction: column;
}
.Grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 1rem;
}
.card {
  display: flex;
  background: #fff;
  /* width: 15rem; */
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  /* border: 2px solid #a39797; */
  /* outline: none; */
  padding: 0.75rem;
  /* height: 10rem; */
  flex-direction: column;
  box-shadow: 0 3px 8px #0000003d;
}
.cardTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  padding: 0.5rem;
}
.userName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.userName p {
  color: #321e1e;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
.userName p:nth-child(3) {
  color: #8f2f2f;
  font-size: 1.15rem;
  text-transform: capitalize;
  font-weight: 500;
}
.userName span {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: black;
}
.role {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.role p {
  color: #321e1e;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
.role p:nth-child(3) {
  color: #8f2f2f;
  font-size: 1.15rem;
  text-transform: capitalize;
  font-weight: 500;
}
.role span {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: black;
}
.organisation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.organisation p {
  color: #321e1e;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
.organisation p:nth-child(3) {
  color: #307384;
  font-size: 1.15rem;
  text-transform: capitalize;
  font-weight: 500;
}
.organisation span {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: black;
}
.cardBottom {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
}

.bottomName p {
  font-size: 1.25rem;
  color: #307384;
  margin: 0.5rem 0;
}
.bottomList {
  margin: 0.5rem 0;
  border: 3px solid #696969c1;
  outline: none;
  text-align: center;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
}
.listSelection {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  padding: 0.5rem 0;
  text-transform: uppercase;
}
.listSelection p {
  font-size: 1.25rem;
  color: #2c7560;
  margin: 0;
}
.listSelection svg {
  font-size: 1.5rem;
  color: #3a1417;
}
.listValue {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 10%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 3px solid #696969c1;
  border-radius: 15px;
  background: aliceblue;
  /* z-index: 222222; */
}
.currentClub {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  flex-direction: column;
}

.clubNames {
  margin: 0;
  font-size: 1.25rem;
  color: #97317c;
}
.clubName {
  margin: 0;
  font-size: 1.25rem;
  color: #0d1c56;
}
.selectClub {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.selectClub select {
  width: 100%;
  font-size: 1.15rem;
  margin: 0.5rem;
  outline: none;
  border: none;
  background: #a29f9f;
  padding: 0.25rem;
  color: #fff;
  border-radius: 5px;
}
.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.75rem 0;
}
.addButton button {
  width: 75%;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.courseMapping{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
  width: 30rem;
}
.courseMapClose{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.courseMapClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.courseMapSection{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: center; 
}
.courseMapButton{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
 
}
.create {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
}
