.container {
  width: 100%;
  height: 100vh;
}
.rewardHeader {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  /* border-radius: 10px; */
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.headerText {
  width: 80%;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: flex-start;
}
.headerText p {
  font-weight: 500;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.rewardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.rewardControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 75vh;
  justify-content: space-between;
  padding: 1rem;
  border: 2px solid #efefef;
  margin: 1rem;
  box-shadow: 0 3px 8px #0000001f;
}
.controlHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.controlHeader p {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: capitalize;
  border-bottom: 2px solid #b8b1c5;
  width: 50%;
  text-align: center;
  padding: 0.5rem 0;
  color: #44476a;
}
.controls {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 80%;
  padding: 0.75rem;
}
.controlGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.rewardSettings {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  border: 0.25px solid #d0d0d0;
  padding: 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 3px 8px #0000003d;
}
.settingLottie {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
}
.settingContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.settingContent p {
  font-size: 1.25rem;
  font-weight: 500;
  color: #7a7a7d;
}
/* .rewardIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0;
}
.rewardButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.rewardButton button {
  font-size: 1.15rem;
  background: linear-gradient(to right, #4e54c8, #8f94fb);
  border: none;
  color: #fff;
  width: 185px;
  border-radius: 5px;
  cursor: pointer;
} */

.rewardTooltip {
  display: none;
  position: absolute;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

.showTooltip {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 10rem;
  background: #f5f5f50f;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 3px 8px #191e9c57;
  top: 25%;
}
.showTooltip svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.showTooltip svg:nth-child(1) {
  color: #24bd40;
  font-size: 1.75rem;
}
.showTooltip svg:nth-child(2) {
  color: #3c80bd;
}
.showTooltip svg:nth-child(3) {
  color: red;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
}
.controlSection {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 40vh;
  padding: 1rem;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  width: 100%;
  box-shadow: 0 3px 8px #0000001f;
}
.rewardHeaderText {
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.rewardHeaderText p {
  font-size: 1.25rem;
  font-weight: 600;
  color: #7c2e79;
}
.rewardHeaderClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
.rewardHeaderClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.mainSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.75rem;
}
.sectionValues {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
}

.sectionValues p {
  font-size: 1.25rem;
  font-weight: 700;
  color: #5a5a81;
}
.sectionValues input {
  font-size: 1.25rem;
  width: 55%;
  outline: none;
  padding: 0.5rem;
  border: 2px solid #b8c2ff;
  border-radius: 5px;
  color: #4b48b2;
}
.createButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.createButton button {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.5rem;
  color: #7e193e;
  width: 125px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
}
.editButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.editButton button {
  width: 10rem;
  border-radius: 7px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.creationExist {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.DeletePopup {
  width: 30rem;
  height: 12rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.deleteClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
  height: 100%;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  color: #857073;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 500;
}
.deleteButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50%;
}
.orgDelete {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.orgDeleteCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
