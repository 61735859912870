.Sidebar {
  width: 250px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 90vh;
  /* background: #fbf7f3; */
  background: #fffbf7;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  border: 1px solid rgba(246, 246, 246, 0.38);
  position: relative;
  border-right: 2px solid #f1f1f1;

}
.Header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 10rem;
}
.logo img {
  width: 100%;
  height: 100%;
}
.userName p {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #252555;
}
.Menu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  /* margin: 1.5rem 0; */
  padding: 0.75rem 0.5rem;
}
.menuIcons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.75rem 0.5rem;
  margin: 0.5rem 0;
  color: #030404;
  cursor: pointer;
}
.menuIcons svg {
  font-size: 1.25rem;
}
.menuIcons p {
  font-size: 1.15rem;
  margin: 0 0.5rem;
  font-weight: 500;
}
.selected {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.75rem 0.5rem;
  margin: 0.5rem 0;
  background: #eee6e1;
  border-radius: 5px;
  cursor: pointer;
}
.selected svg {
  font-size: 1.25rem;
}
.selected p {
  font-size: 1.15rem;
  margin: 0 0.5rem;
  font-weight: 500;
}
.Popup {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%);
    background-color: #eee6e1;
    border: 2px solid #ffffff;
    border-radius: 10px;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25rem;
    height: 12rem;
    color: #030404;
    z-index: 9999999999;
}
.popupText p {
  font-size: 1.25rem;
  font-weight: 500;
}
.PopupButton {
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
  justify-content: space-between;
}
.PopupButton button {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: 2px solid #ffffff;
  outline: none;
  width: 115px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  /* color: #fff; */
  background: none;
  cursor: pointer;
}
.popupClose {
  display: flex;
  width: 100%;
  font-size: 1.25rem;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.popupSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}
.popupText {
  display: flex;
  align-items: center;
  height: 50%;
}
.logoutOverlay {
  width: 85vw;
  height: 95vh;
  position: absolute;
  background-color: transparent;
  transform: translate(9%, -50%);
  top: 50%;
  left: 50%;
  z-index: 999;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
}
