.Container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: #fff;
  }
  .Container::-webkit-scrollbar {
    display: none;
  }
  .Header {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    color: #131010;
    height: 4rem;
    border-radius: 10px;
    margin: 1rem;
    box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
      0.3em 0.3em 1em #6d6d6d4d;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .headerText {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .headerText h5 {
    font-weight: 500;
    margin: 0;
    font-size: 1.35rem;
    text-transform: uppercase;
  }
  .headerButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
  }
  .headerButton button {
    background: #efe4dd;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #7e193e;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #be5656;
  }
  .Overlay {
    background-color: #3e3e3e1a;
    height: 100vh;
    position: fixed;
    width: 100vw;
    top: 0;
  }
  .alertSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .alertSection p {
    font-size: 1.25rem;
    color: green;
    text-transform: capitalize;
  }
  .alertSection p:nth-child(2) {
    font-size: 1.15rem;
    color: #99334d;
  }
  .confirmationSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem 0;
  }
  .asessmentCreationSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
  }
  .selectionSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 1rem;
  }
  
  .generationContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
  }
  .generationValue {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
  }
  .generationValue p {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .asessmentTypes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
  }
  .questionSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 75vh;
    justify-content: space-between;
    padding: 1rem;
    border: 2px solid #efefef;
    margin: 1rem;
    box-shadow: 0 3px 8px #0000001f;
  }
  .noSelection {
    width: 100%;
    height: 75vh;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .fillQuestionSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
  .questionCreation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    height: 50%;
  }
  .creationTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 0;
    height: 20%;
  }
  .questionCreationTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .questionCreationTop svg {
    font-size: 1.5rem;
    color: #2e2e89;
  }
  .questionCreationTop p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 0.75rem;
    text-transform: capitalize;
  }
  .questionCreationTop span {
    font-size: 1.5rem;
    color: red;
    font-weight: 700;
  }
  .creationTopLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
  }
  .creationTopLeft svg {
    font-size: 1.5rem;
    color: #2e2e89;
  }
  .creationTopLeft p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 0.75rem;
    /* text-transform: capitalize; */
  }
  .creationTopLeft span {
    font-size: 1.5rem;
    color: red;
    font-weight: 700;
  }
  .creationTopRight {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .creationBottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
    height: 18vh;
  }
  .creationBottomBlank {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 0;
    height: 75%;
  }
  .textArea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .formatButtons {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }
  .optionSet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    padding: 0.75rem 0;
  }
  .optionTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
    margin-top: 1.5rem ;
  }
  .optionTop svg {
    font-size: 1.5rem;
    color: #39c95b;
  }
  .optionTop p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 0.75rem;
    /* text-transform: capitalize; */
  }
  .optionTop p:nth-child(3) {
    color: #094778;
    font-size: 1.15rem;
    font-weight: 400;
  }
  .optionTop span {
    font-size: 1.5rem;
    color: red;
    font-weight: 700;
  }
  .optionBottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 0;
  }
  .optionBottomLeft {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
    flex-direction: column;
  }
  .blankValues {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    padding: 0.5rem 0;
  }
  .blankValues p {
    font-size: 1.25rem;
    color: #740202;
    font-weight: 700;
    margin-right: 0.5rem;
  }
  .optionItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
  }
  .optionBottomRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
  }
  .mainOptionSet {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0.75rem 0;
    margin-top: 1.5rem;

  }
  .firstOptionSet {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .firstOptionTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .firstOptionTop svg {
    font-size: 1.5rem;
    color: #c93966;
  }
  .firstOptionTop p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 0.75rem;
    text-transform: capitalize;
  }
  .firstOptionSetValues {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .firstOptionAdd {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .secondOptionSet {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .secondOptionTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .secondOptionTop svg {
    font-size: 1.5rem;
    color: #c9c639;
  }
  .secondOptionTop p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 0.75rem;
    text-transform: capitalize;
  }
  .secondOptionSetValues {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .secondOptionAdd {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .matchAnswerKey {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .matchAnswerTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .matchAnswerTop p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 0.75rem;
    text-transform: capitalize;
  }
  .matchAnswerValues {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .matchAnswerTop svg {
    font-size: 1.5rem;
    color: #55c939;
  }
  
  .answerKeyAdd {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 0;
  }
  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
  }
  .questionCreateButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.75rem;
  }
  .questionCreateButton button {
    background: #efe4dd;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #7e193e;
    /* width: 115px; */
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #be5656;
  }
  .questionClearButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .questionClearButton button {
    width: 8rem;
    border-radius: 6px;
    background-color: #e1e4ee;
    outline: none;
    font-size: 1.15rem;
    color: #20126d;
    padding: 0.5rem;
    border: 2px solid #b2a5d3;
    cursor: pointer;
  }
  .aiGenerationSection {
    width: 100%;
    height: 75vh;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .aiButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .aiButton button {
    background: #efe4dd;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #7e193e;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #be5656;
  }
  .WhiteBoradContainer{
    width: 60rem;
  height: 36rem;
  border-radius: 16px;
  background-color: #fff;
  position: fixed;
  top: 19%;
  right: 1%;
  border: 1px solid #dbdbdb;
  /* transform: translate(-50%, -50%); */
  animation: slideIn 0.5s forwards;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 99;
  }
  .closing {
    animation: slideOut 0.5s; /* Adjust the duration as needed */
  }
  @keyframes slideIn {
    from {
      right: -60rem; /* Off-screen initially */
    }
    to {
      right: 1rem; /* Final position */
    }
  }
  @keyframes slideOut {
    from {
      right: 1rem; /* Initial position when closing */
    }
    to {
      right: -60rem; /* Move off-screen to the right */
    }
  }
  .WhiteBoradHead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    padding: 0rem 1rem;
    margin: 0;
    border: none;
    border-radius:15px 15px 0 0 ;
  }
  .WhiteBoradIcon{
    cursor: pointer;
    font-size: 1.25rem;
    padding: 8px;
  }
  .WhiteBoradContent{
    height: 32rem;
    padding:.5rem 1rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  
  .chatboticon {
    position: fixed;
    top: 50%;
   right: 0%;
    /* color: #fff;  */
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* animation: slideIn 0.5s forwards; */
    z-index: 99;
  }
  
  .Iconhead {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #000;
    font-weight: bold;
    font-size: 1.25rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
  }
  .icontext {
    padding: .5rem;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    /* background-color: #e4c395; */
    border-radius: 6px;
    /* border: 1px solid; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#1a1e5d;
    background-color: #fff;
  }
  .icontext p{
    margin: 0;
    padding: 0;
  }
  .WhiteboardFloatingIcon{
  font-size: 1.5rem;
  margin: 2px 0;
  color:#1a1e5d;
  }
  .ChatImage {
    width: 11rem;
    /* border-radius: 50%; */
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    /* background-color: #0f8bea; */
  }
  .ChatImage img {
    width: 100%;
  }
  .MicSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .MicSection button{
    margin:1.5rem 0;
    
  }
  .MicSection input{
    /* padding:10px 4px */
  
  }
  .optionBottomDictation {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
  }
  .ImageInput {
    width: 100%;
  }
  .crosswordcontainer{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .crosswordpuzzle {
    display: grid;
    grid-template-columns: repeat(10, 50px);
    grid-template-rows: repeat(10, 50px);
    gap: 0;
    padding: 4px;
    
  }
  
  .PuzzleQuestion{
    padding: 0 1rem;
    width: 100%;
  }
  
  .cell {
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    border: 1px solid #000;
    padding: .75rem; /* Remove padding */
    margin: 0; /* Remove margin */
    outline: none; /* Remove outline */
  }
  .clueNumber {
    position: absolute;
    top: 0;
    right: 0;
    font-size: smaller;
  }
  .QuestionAcross{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .QuestionAcrossInputContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .QuestionAcrossInputContainer input{
    font-family: "Poppins";
    width: 100%;
    font-size: 1.15rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    border-radius: 5px;
    margin: 0.5rem 0;
    border: 1px solid #bfaaaac1;
    outline: none;
    text-align: start;
  }
  
  .QuestionDown{
    display: flex;
    flex-direction: column;
  }
  .QuestionDownInputContainer{
    display: flex;
    flex-direction: column;
  }
  .QuestionDownInputContainer input{
    font-family: "Poppins";
    width: 100%;
    font-size: 1.15rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    border-radius: 5px;
    margin: 0.5rem 0;
    border: 1px solid #bfaaaac1;
    outline: none;
    text-align: start;
  }
  .cellContainer {
    position: relative;
  }
  
  .clueNumber {
    position: absolute;
    top: 2px;  /* Adjust this value to fine-tune the vertical position */
    right: 2px;  /* Adjust this value to fine-tune the horizontal position */
    font-size: 10px;  /* Adjust this value for the desired font size */
    color: red;  /* Adjust this value for the desired color */
  }
  .MediaPopup {
    position: absolute;
    top: 50%;
    left: 57%;
    z-index: 999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 80%;
  }
  .MediaPopupHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 0 3px 8px #0000001f;
    padding: 0.5rem 0;
  }
  .MediaPopupHeaderText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .MediaPopupHeaderText h5 {
    font-size: 1.15rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #727272;
    padding: 0 6px;
  }
  .MediaPopupHeaderClose {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .MediaPopupHeaderClose svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .MediaPopupSection {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: flex-start;
    width: 100%;
    margin: 0.5rem 0;
  }
  .ImageGRid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    width: 100%;
    height: 60vh;
    padding: 8px;
    overflow-y: scroll;
  }
  .ImageGRid::-webkit-scrollbar{
    display: none;
  }
  .Card{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    height: 12rem;
  }
  .Card img{
  width: 100%;
  }
  .tabSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .MediaPopupInputTab{
    height: 60vh;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
  }
  .MediaPopupInputTab button {
    border: none;
    background: #9f2f2f;
    font-size: 1.25rem;
    color: #ffff;
    padding: 0.75rem;
    width: 150px;
    border-radius: 5px;
    margin: 0 0.75rem;
  }
  .MicSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .MicSection button{
    margin:1.5rem 0;
    
  }
  .MicSection input{
    /* padding:10px 4px */
  
  }
  .optionBottomDictation {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
  }
  .ImageInput {
    width: 100%;
  }